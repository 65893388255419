<template>
    <div>
        <b-card no-body class="border mt-1">
            <b-card-header class="p-1">
                <b-card-title>Collection NFTs</b-card-title>
            </b-card-header>
            <b-table
             :busy="isBusy"
            :items="items"
      :fields="fields"
      striped
      responsive
      hover
      small
        @row-clicked="openCollection">
          <template #table-busy>
        <div class="text-center text-success my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
     <template #cell(data.avatar)="data">
        <b-avatar :src="data.value" />
      </template>
      <template #cell(floorPrice)="data">
        {{data.value/1000000000}}
      </template>
      <template #cell(data.volume)="data">
        {{data.value/1000000000}}
      </template>
            </b-table>
        </b-card>

    </div>
</template>
<script>
import {BCard,BCardHeader,BCardTitle,BTable,BAvatar,BSpinner} from "bootstrap-vue"
export default {
    components:{BCard,BCardHeader,BCardTitle,BTable,BAvatar,BSpinner},
    data(){
        return{
            fields:[{ key: 'data.avatar', label: '' },{key:'data.collection',label:'Collection'},
            {key:'data.items',label:'Items'},{key:'totalAttributes',label:'Attributes'},
            {key:'floorPrice',label:'Floor Price'},{key:'data.volume',label:'volume 24H'}],
             items:[],
              offset:0,
              isBusy: false
        }
    },
    mounted(){
        this.getCollectionNFT()

    },
    methods:{
        getCollectionNFT:async function(){
             const vm=this;
      var salir=true
      var arr=[]
        this.isBusy = true;
     const axios = require("axios");
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
     while (salir) {
       const response=await axios.get("https://api.solscan.io/collection?sortBy=volume&offset="+vm.offset+"&limit=24&cluster=",config)
         for (let x = 0; x < response.data.data.length;x++) {
            if (response.data.data[x].data.collection=="Okay Bears" || response.data.data[x].data.collection=="Blocksmith Labs") { 
              arr.push(response.data.data[x]); 
              if (arr.length==2){
                salir=false
                  vm.isBusy = false;
              }
            } 
        }
        vm.offset+=25 
        }
        this.items=arr
          vm.isBusy = false;
        console.log(this.items);
        },
         openCollection:function(item){
            let data={nft:item}
             localStorage.setItem('dataNFT',null);
            localStorage.removeItem('dataNFT'); 
            localStorage.setItem('dataNFT',JSON.stringify(data));
            this.$router.push({ name:'collection'})
    },

    }
}
</script>